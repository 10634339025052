import "../styles/section1.css";

export default function Section1() {
  return (
    <>
      <section className="heroBanner">
        <div className="heroText text-center">
          <h2 className="text-white">Transforming Business through</h2>
          <h3 className="text-white">End-To-End Solution and Services</h3>
          <div className="buttonWrapper">
            <button className="heroBtn btn-1">
             Learn More{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                id="arrow"
                x="0px"
                y="0px"
                width="11.121px"
                height="19.414px"
                viewBox="0 0 11.121 19.414"
                enableBackground="new 0 0 11.121 19.414"
          
              >
                <polygon
                  fill="#fff"
                  points="1.414,19.414 0,18 8.293,9.707 0,1.414 1.414,0 11.121,9.707 "
                />
              </svg>
            </button>
          </div>
        </div>
        <svg
          className="heroWave"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,224L48,234.7C96,245,192,267,288,240C384,213,480,139,576,138.7C672,139,768,213,864,229.3C960,245,1056,203,1152,170.7C1248,139,1344,117,1392,106.7L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </section>
    </>
  );
}
