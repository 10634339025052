import "./service.css";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";

const Network = () => {
  return (
    <>
      <Header />
      <div className="banner">
        <div className="headerArea">
          <h1 className="headerText">Network Services</h1>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,192L120,202.7C240,213,480,235,720,208C960,181,1200,107,1320,69.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div className="container">
        <div className="cloudService4 fmsIconsData">
          <h2 className="headText">Network</h2>
          <p className="pText">
            Network Right from consultation to planning and successful
            implementation, we are with you at every stage to ensure that you
            enjoy end-to-end networking solutions and improved business
            performance.
          </p>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-database circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Data Centre Network</h4>
                  <p className="cardText">
                    Delivering robust and innovative solutions to ensure
                    uninterrupted data center network. From network function
                    virtualization to DC network migration, we assist at every
                    stage.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-brands fa-usb circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Data Centre Network</h4>
                  <p className="cardText">
                    Enabling new communication opportunities and helping
                    businesses expand their network infrastructure with
                    high-performance LAN & WAN solutions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-shuffle circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Routing & Switching</h4>
                  <p className="cardText">
                    Routing & Switching Connect your employees, customers, and
                    other stakeholders with critical applications using robust
                    routing & switching solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-wifi circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Wireless</h4>
                  <p className="cardText">
                    To grow with fast and ever-changing IT environment, your
                    business needs to invest in robust WAP, NAP and wireless
                    controller.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-key circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Virtual Private Network (VPN)</h4>
                  <p className="cardText">
                    Through a virtual point-to-point connection, your employees
                    can access a private network, and send and receive data even
                    when working remotely.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-arrow-right-arrow-left circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Structured Cabling Solutions</h4>
                  <p className="cardText">
                    Say no to network downtime with adequately maintained
                    network cabling. Now, you don’t have to struggle with poorly
                    installed cabling.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cloudService3">
          <div>
            <h3 className="h3Text">
              Industries that Benefit the Most
              <br /> from Network Services
            </h3>
            <p className="text-center pText">
              These sectors are primarily leveraging these latest IT services to
              unlock their growth potential and uncover newer avenues for their
              business.
            </p>
          </div>
          <div className="imageContainer">
            <div className="row">
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/air.jpg" alt="img1" />
                <div className="overlay-text">
                  <h3>Air Transport Service</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img
                  className="cloudImg"
                  src="/e-taal.webp"
                  alt="img2"
                />
                <div className="overlay-text">
                  <h3>Mobile Seva</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/Lan.jpg" alt="img3" />
                <div className="overlay-text">
                  <h3>Network</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/slide5.jpg" alt="img4" />
                <div className="overlay-text">
                  <h3>PMIS</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Network;
