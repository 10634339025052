import "./service.css";
import Footer from "../components/Footer.js";
import Header from "../components/Header.js";

const Backup = () => {
  return (
    <>
      <Header />
      <div className="banner">
        <div className="headerArea">
          <h1 className="headerText">Backup Service</h1>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,192L120,202.7C240,213,480,235,720,208C960,181,1200,107,1320,69.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div className="backupContainer">
        <div className="cloudService1">
          <h2 className="headText">Backup</h2>
          <p className="pText">
            With expert consultation for backup solutions, we help businesses
            create a data infrastructure that efficiently captures, manages,
            protects and deliver data with superior performance.
          </p>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-cloud circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Hybrid Cloud Architecture</h4>
                  <p className="cardText">
                    We ensure safe and comprehensive data protection for your
                    entire environment, be it physical, virtual, cloud,
                    applications, mobile & endpoints.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-money-bill-1 circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Reduced Data Backup Costs</h4>
                  <p className="cardText">
                    Advanced data backup and protection solutions will drive
                    down your total cost of ownership and enhance the backup
                    performance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-lock circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Secured SaaS Application Data</h4>
                  <p className="cardText">
                    Your SaaS application data would not be susceptible to data
                    loss. Switch to the best of backup solutions today.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-shield-halved circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">
                    Safeguard Vital Enterprise Applications
                  </h4>
                  <p className="cardText">
                    Industry-leading data backup and recovery solutions to give
                    IT teams and entrepreneurs the confidence that their data is
                    readily available.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-laptop circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Robust Backup Infrastructure</h4>
                  <p className="cardText">
                    High-performance backup and recovery solutions to meet all
                    your current and future IT requirements.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-gauge circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Faster Backup & Recovery</h4>
                  <p className="cardText">
                    The best-of-breed backup solutions allow you to quickly back
                    up and recover workloads anywhere and anytime with reduced
                    management complexities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="imageContainer">
        <div className="backupText">
          <h3 className="h3Text">
            Industries that Benefit the Most
            <br /> from Backup Services
          </h3>
          <p className="text-center pText">
            These sectors are primarily leveraging these latest IT services to
            unlock their growth potential and uncover newer avenues for their
            business.
          </p>
        </div>
        <div className="row">
          <div className="col-md-3 ser3">
            <img className="cloudImg" src="/e-court.jpg" alt="img1" />
            <div className="overlay-text">
              <h3>E-Court</h3>
            </div>
          </div>
          <div className="col-md-3 ser3">
            <img className="cloudImg" src="/data.gov.webp" alt="img2" />
            <div className="overlay-text">
              <h3>Data.gov.in</h3>
            </div>
          </div>
          <div className="col-md-3 ser3">
            <img className="cloudImg" src="/GOI.webp" alt="img3" />
            <div className="overlay-text">
              <h3>GOI Web Directory</h3>
            </div>
          </div>
          <div className="col-md-3 ser3">
            <img className="cloudImg" src="/digitalgovt.jpg" alt="img4" />
            <div className="overlay-text">
              <h3>National E-Governance Division</h3>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Backup;
