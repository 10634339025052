import React from "react";
import styled from "styled-components";
import Marquee from "../components/Marquee.js"

function App() {

  return (
    <AppContainer>
      <Wrapper>
        <Text>With Great Outcomes.</Text>
        <Note>Our customers have gotten offers from awesome companies.</Note>
        <Marquee />
      </Wrapper>
    </AppContainer>
  );
}

export default App;

const AppContainer = styled.div`
  width: 100%;
  height:70vh;
  color: #000000;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

 @media (max-width:767px) {
 width:100%;
 text-align: center;
 }
`;

const Text = styled.div`
  font-size: 35px;
  font-weight: 500;
  text-align:center;
  margin-bottom: 10px;
  color: #02203c;
`;

const Note = styled.div`
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 5%;
  text-align: center;
  color: #7c8e9a;

   @media (max-width:767px) {
    margin-bottom: 20%;
   }
`;