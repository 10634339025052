import '../styles/header.css'
import Layout from '../components/Layout.js'
import Section1 from './Section1.js'
import Section2 from './Section2.js';
import Section3 from './Section3.js';
import Section4 from './Section4.js';
import Section5 from './Section5.js';
import Section6 from './Section6.js';


const Home = () => {
  return (
    <>
    <Layout>
     {/* {Home section Hero banner} */}
     <Section1/>
     <Section2/>
    <Section6 />
    <Section4 />
    <Section3 />
    <Section5 />
  
    </Layout>
    </>

  )
}

export default Home