import "../styles/section5.css";

const Section5 = () => {
    return (

    <div className="section5">
            <div className="contactWrapper">
                <div className="title">
                <h1>contact us form</h1>
                </div>
                <div className="contactForm">
                    <div className="inputFields">
                        <input type="text" className="input" placeholder="Name"/>
                        <input type="text" className="input" placeholder="Email Address"/>
                        <input type="text" className="input" placeholder="Phone"/>
                        <input type="text" className="input" placeholder="Subject"/>
                    </div>
                    <div className="msg">
                        <textarea placeholder="Message"></textarea>
                        <button className="btn btn-outline-warning">send</button>
                    </div>
                </div>
        </div>
    </div>

)
}

export default Section5;
