import "./service.css";
import Footer from "../components/Footer.js";
import Header from "../components/Header.js";

const Analytics = () => {
  return (
    <>
      <Header />
      <div className="banner">
        <div className="headerArea">
          <h1 className="headerText">Analytics Service</h1>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,192L120,202.7C240,213,480,235,720,208C960,181,1200,107,1320,69.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>

      <div className="container">
        <div className="cloudService4">
        <h2 className="headText">Analytics</h2>
          <p className="pText mt-3">
          You don’t need to sweat over harnessing the power of big data. We can help you make the most of big data technologies and derive 
          actionable insights from the ongoing data that your teams collect every day.
          </p>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-cloud circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Advanced Data Analytics</h4>
                  <p className="cardText">
                  From predictive data analytics to big data and data mining, we include all these areas 
                  to improve various aspects of your operations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-regular fa-money-bill-1 circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">
                  Big Data Consulting
                  </h4>
                  <p className="cardText">
                  We provide expert business data consulting services to architecture 
                  design and technology selection, implementation and support.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-lock circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Data Management</h4>
                  <p className="cardText">
                  Our data management services include validating, protecting, and processing
                   the required data to ensure the timeliness of information for users.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-shield-halved circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading"> Strategic Consulting</h4>
                  <p className="cardText">
                  Creating the strategy and developing big data maturity roadmap to help our 
                  clients realize new possibilities with accelerated growth.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-calculator circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Predictive & Prescriptive Analytics</h4>
                  <p className="cardText">
                  Helping businesses utilize analytics & insights to identify actionable steps and make better business decisions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-weight-scale circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Data Analytics Outsourcing</h4>
                  <p className="cardText">
                  We provide our clients with detailed reports and recommendations to improve their business productivity.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="cloudService3">
          <div>
            <h3 className="h3Text">
            Industries that Benefit the Most from Analytics Services
            </h3>
            <p className="text-center pText">
            These sectors are primarily leveraging these latest IT services to unlock their growth
             potential and uncover newer avenues for their business.
            </p>
          </div>
          <div className="imageContainer">
            <div className="row">
              <div className="col-md-4 ser3">
                <img className="cloudImg" src="/slide2.jpg" alt="nkn" />
                <div className="overlay-text">
                  <h3>Nation Knowledge Network</h3>
                </div>
              </div>
              <div className="col-md-4 ser3">
                <img className="cloudImg" src="/e1.jpg" alt="pmis" />
                <div className="overlay-text">
                  <h3>PMIS</h3>
                </div>
              </div>
              <div className="col-md-4 ser3">
                <img className="cloudImg" src="/slide5.jpg" alt="etaal" />
                <div className="overlay-text">
                  <h3>E-Taal</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 ser3">
                <img className="cloudImg" src="/e5.webp" alt="eprocurement" />
                <div className="overlay-text">
                  <h3>E-Procurement</h3>
                </div>
              </div>
              <div className="col-md-4 ser3">
                <img className="cloudImg" src="/e4.webp" alt="ras" />
                <div className="overlay-text">
                  <h3>Rapid Assessment System</h3>
                </div>
              </div>
              <div className="col-md-4 ser3">
                <img className="cloudImg" src="/s8.webp" alt="digiIndia" />
                <div className="overlay-text">
                  <h3>Digital India</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Analytics;
