import "../styles/section4.css";

const Section4 = () => {
return(
<div className="container">
<h2 className="service4Text">Our Services</h2>
        <div className="serviceWrapper">
        
              <div className="row">
                <div className="service col-md-4">
                  <i className="ri-macbook-line"></i>
                  <h3>Analytics</h3>
                  <p>We can help you make the most of big data technologies and derive 
                    actionable insights from the ongoing data that your teams collect every day.</p>
                </div>
                <div className="service col-md-4">
                  <i className="ri-store-3-line"></i>
                  <h3>Backup</h3>
                  <p> we help businesses create a data infrastructure that efficiently captures, 
                    manages, protects and deliver data with superior performance.</p>
                </div>
                <div className="service col-md-4">
                  <i className="ri-database-2-line"></i>
                  <h3>Cloud</h3>
                  <p> we provide contractual, agreement-based cloud services while assessing your IT infra and creating the migration pathway. </p>
                </div>
                </div>
                <div className="row">
                <div className="service col-md-4">
                  <i className="ri-palette-line"></i>
                  <h3>Data Center</h3>
                  <p>You can run multiple applications safely across cloud, on premise and the network with our data center management services.</p>
                </div>
                <div className="service col-md-4">
                  <i className="ri-android-line"></i>
                  <h3>FMS</h3>
                  <p>Facility Management Service, Deploying manpower across corporate and government bodies, such as NICSI, NIC and FDDI.</p>
                </div>
                <div className="service col-md-4">
                  <i className="ri-account-box-line"></i>
                  <h3>Network & Security</h3>
                  <p>These sectors are primarily leveraging these latest IT services to unlock their growth potential and uncover newer avenues for their business.</p>
                </div>
              </div>
        </div>
</div>
)
};

export default Section4;
