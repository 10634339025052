import "../styles/section6.css";
import {Link} from 'react-router-dom';

const Section6 = () => {
  return (
    <>
      <div className="gallery">
        <figure className="grid">
          <img src="/s1.jpg"  alt="img1"
          />
           <h5 className="textHide">Service</h5>
          <figcaption>
            <h2>
              <span>Analytics</span> Service
            </h2>
            <p>Our data and analytics experts reimagine what's possible to fuel growth and customer value.</p>
            <Link to="/service/analytics"></Link>
          </figcaption>
        </figure>
        <figure className="grid">
          <img src="/s2.png"  alt="img2" />
          <h5 className="textHide">Service</h5>
          <figcaption>
            <h2>
              <span>Backup</span> Service
            </h2>
            <p>
            Rethink tech stacks with our customer-centric solutions. Browse our technology services.
            </p>
            <Link to="/service/backup">.</Link>
          </figcaption>
        </figure>
        <figure className="grid">
          <img src="/s3.webp" alt="img3"
          />
           <h5 className="textHide">Service</h5>
          <figcaption>
            <h2>
            <span>Cloud</span> Service
            </h2>
            <p>
              That intelligent life exists elsewhere in the universe is that
              none of it has tried to contact us.
            </p>
            <Link to="/service/cloud">.</Link>
          </figcaption>
        </figure>
        <figure className="grid">
          <img   src="/s4.webp"  alt="img4"
          />
           <h5 className="textHide">Service</h5>
          <figcaption>
            <h2>
            <span>Network </span> Service
            </h2>
            <p>
             See what’s possible when you place AI at the heart of your business.
            </p>
            <Link to="/service/network">.</Link>
          </figcaption>
        </figure>
      </div>
      <div className="gallery">
        <figure className="grid">
          <img src="s5.jpg" alt="img5"
          />
           <h5 className="textHide">Service</h5>
          <figcaption>
            <h2>
            <span>Security</span> Service
            </h2>
            <p>Stay ahead of risks and on top of complex regulations with our future-proof services.</p>
            <Link to="/service/security">.</Link>
          </figcaption>
        </figure>
        <figure className="grid">
          <img src="s7.webp" alt="img6"
          />
           <h5 className="textHide">Service</h5>
          <figcaption>
            <h2>
              <span>Infrastructure </span> Service
            </h2>
            <p>
Start the transformation with greater visibility and control.
            </p>
            <Link to="/service/infrastructure">.</Link>
          </figcaption>
        </figure>
        <figure className="grid">
          <img src="s8.webp"  alt="img7"
          />
           <h5 className="textHide">Service</h5>
          <figcaption>
            <h2>
           <span>Virtualization</span> Service
            </h2>
            <p>
            Streamline finance and enhance decision making for resilience, innovation, and growth.
            </p>
            <Link to="/service/virtualization">.</Link>
          </figcaption>
        </figure>
        <figure className="grid">
          <img src="s6.jpg" alt="img8"
          />
           <h5 className="textHide">Service</h5>
          <figcaption>
            <h2>
            <span>Software </span> Service
            </h2>
            <p>
            Data-driven marketing and sales strategies that turn today’s customers into lifelong fans.
            </p>
            <Link to="/service/licensing">.</Link>
          </figcaption>
        </figure>
      </div>
    </>
  );
};

export default Section6;
