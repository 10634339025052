import "../styles/contact.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Contact = () => {

  return (
    <>
      <Header />
      <div className="banner">
        <div className="headerArea">
          <h1 className="headerText">Contact Us</h1>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,192L120,202.7C240,213,480,235,720,208C960,181,1200,107,1320,69.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>
      <section className="contact-page-section">
      <div className="container">
          <div className="sec-title">
              <div className="title">Contact Us</div>
                <h2>Let's Get in Touch.</h2>
            </div>
            <div className="inner-container">
              <div className="row clearfix">
                    <div className="form-column col-md-8 col-sm-12 col-xs-12">
                      <div className="inner-column">
                          
                            <div className="contact-form">
                                <form method="post" action="" id="contact-form">
                                    <div className="row clearfix">
                                        <div className="form-group col-md-6 col-sm-6 co-xs-12">
                                            <input type="text" name="name" value="" placeholder="Name" required />
                                        </div>
                                        <div className="form-group col-md-6 col-sm-6 co-xs-12">
                                            <input type="email" name="email" value="" placeholder="Email" required />
                                        </div>
                                        <div className="form-group col-md-6 col-sm-6 co-xs-12">
                                            <input type="text" name="subject" value="" placeholder="Subject" required />
                                        </div>
                                        <div className="form-group col-md-6 col-sm-6 co-xs-12">
                                            <input type="text" name="phone" value="" placeholder="Phone" required />
                                        </div>
                                        <div className="form-group col-md-12 col-sm-12 co-xs-12">
                                            <textarea name="message" placeholder="Massage"></textarea>
                                        </div>
                                        <div className="form-group col-md-12 col-sm-12 co-xs-12">
                                            <button type="submit" className="theme-btn btn-style-one">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                          
                            
                        </div>
                    </div>
                    
                 
                    <div className="info-column col-md-4 col-sm-12 col-xs-12">
                      <div className="inner-column">
                          <h2>Contact Info</h2>
                            <ul className="list-info">
                              <li><i className="fas fa-globe"></i>Floor No-5, Office No- 506, Lal-ganga business park, Pachpedi Naka, Raipur, Chhattisgarh</li>
                                <li><i className="far fa-envelope"></i>www.jrglobalservices.com </li>
                                <li><i className="fas fa-phone"></i>+91 7999108880 <br /> +91 7713554780</li>
                            </ul>
                            <ul className="social-icon-four">
                                <li className="follow">Follow on: </li>
                                <li><a href="https://www.facebook.com/"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://www.twitter.com/"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
                                <li><a href="https://www.dribble.com/"><i className="fab fa-dribbble"></i></a></li>
                                <li><a href="https://www.pintrest.com/"><i className="fab fa-pinterest-p"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
      <Footer />
    </>
  );
};

export default Contact;
