import "./service.css";
import Footer from "../components/Footer.js";
import Header from "../components/Header.js";

const Infrastructure = () => {
  return (
    <>
     <Header />
     <div className="banner">
        <div className="headerArea">
          <h1 className="headerText">Infrastructure Services</h1>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,192L120,202.7C240,213,480,235,720,208C960,181,1200,107,1320,69.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>
    <div className='container'>
    <div className="cloudService4 fmsIconsData">
          <h2 className="headText">Infrastructure</h2>
          <p className="pText">
          Functioning as a one-stop shop for all your IT needs, we help in identifying, integrating and installing data centers, 
          networking, computing devices, server, storage and virtualization.
          </p>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-database circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Data Center Solutions & Services</h4>
                  <p className="cardText">
                  Building or migrating to complex data centers seems easy and convenient with the best data center solutions and services.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-regular fa-user circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">
                  Enterprise Mobility Solutions
                  </h4>
                  <p className="cardText">
                  Optimize your operational efficiency with robust enterprise mobility solutions. Give your workforce the mobility they require to perform their daily tasks on the go uninterruptedly.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-gauge circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">High Performance Computing Solutions</h4>
                  <p className="cardText">
                  Now you can execute even highly complex and intensive tasks in real time with high-performance computing solutions and accelerate your time to results.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-network-wired circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">
                  Enterprise Network Solutions
                  </h4>
                  <p className="cardText">
                  Enjoy 360-degree network visibility with optimized access to cloud applications, mobile workforce and the Internet of Things (IoT).
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-users circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Enterprise Management Solutions (EMS)</h4>
                  <p className="cardText">
                  With the best enterprise management solutions, you can adopt a dynamic business environment while maintaining the high level of expertise.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-cloud circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Private Cloud Solutions</h4>
                  <p className="cardText">
                  Enjoy multi-tenancy and powerful API features with private cloud solutions. You can support multiple users through a single platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
         
        </div>
    <div className="cloudService3">
          <div>
            <h3 className="h3Text">
            Industries that Benefit the Most<br/>from Infrastructure Services
            </h3>
            <p className="text-center pText">
            These sectors are primarily leveraging these latest IT services to unlock their growth potential and uncover newer avenues for their business.
            </p>
          </div>
          <div className="imageContainer">
            <div className="row">
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/digitalgovt.jpg" alt="img1" />
                <div className="overlay-text">
                  <h3>Digital Government</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/s1.jpg" alt="img1" />
                <div className="overlay-text">
                  <h3>Software Development</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/workpeople.jpg" alt="img1" />
                <div className="overlay-text">
                  <h3>Skill Development Scheme</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/e-taal.webp" alt="img1" />
                <div className="overlay-text">
                  <h3>Mobile Seva</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/phd.jpeg" alt="img1" />
                <div className="overlay-text">
                  <h3>PHD Scheme</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/shoot.webp" alt="img1" />
                <div className="overlay-text">
                  <h3>Film and Television Institute</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/industry.jpg" alt="img1" />
                <div className="overlay-text">
                  <h3>Electrical Equipment Industry</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/s5.jpg" alt="img1" />
                <div className="overlay-text">
                  <h3>Enterprise Management </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        
    </div>
    <Footer/>
    </>
  )
}

export default Infrastructure