import "./service.css";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";

const Cloud = () => {
  return (
    <>
      <Header />
      <div className="banner">
        <div className="headerArea">
          <h1 className="headerText">Cloud Service</h1>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,192L120,202.7C240,213,480,235,720,208C960,181,1200,107,1320,69.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div className="container">
        <div className="cloudService1">
        <h2 className="headText">Cloud</h2>
          <p className="pText">
          JR Global provides managed services to improve the cloud transformation journey for 
          businesses across different industries. As a managed service provider (MSP), we provide contractual, 
          agreement-based cloud services while assessing your IT infra and creating the migration pathway. 
          </p>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-gears circleIcon"></i>
                </div>
                <div className="col-md-9">
                  <h4 className="cardHeading">Transform</h4>
                  <p className="cardText">
                    Our services include planning and execution of the processes
                    related to rehost (Lift & Shift), re-platform and refactor.
                    The entire IT infrastructure is moved to the cloud in
                    alignment with your business objective.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-laptop-code circleIcon"></i>
                </div>
                <div className="col-md-9">
                  <h4 className="cardHeading">Secure</h4>
                  <p className="cardText">
                    We adopt a phased migration strategy while adhering to
                    compliance standards. Centralized monitoring and data
                    protection are some of our proven strategies to safeguard
                    your critical data on the cloud.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-regular fa-file-code circleIcon"></i>
                </div>
                <div className="col-md-9">
                  <h4 className="cardHeading">Protect</h4>
                  <p className="cardText">
                    Our robust data protection steps on the cloud ensure
                    complete security and backup of your business-critical data
                    related to employees and customers. Every corporate
                    information is secured against cybersecurity threats and
                    disasters.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-gear circleIcon"></i>
                </div>
                <div className="col-md-9">
                  <h4 className="cardHeading">Monitor</h4>
                  <p className="cardText">
                    The cloud monitoring method by our experts includes network
                    monitoring, log diagnostics and analysis of every workflow.
                    We ensure uninterrupted functioning of servers, websites,
                    and applications with 99.9% uptime.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-laptop circleIcon"></i>
                </div>
                <div className="col-md-9">
                  <h4 className="cardHeading">Configure</h4>
                  <p className="cardText">
                    Configurations, file updates, automation and scripting
                    processes are managed by industry veterans at SISL. Any
                    configuration change across cloud services is updated in
                    real-time to ensure the security of business-critical data.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-regular fa-file-lines circleIcon"></i>
                </div>
                <div className="col-md-9">
                  <h4 className="cardHeading">Govern</h4>
                  <p className="cardText">
                    The Cloud Governance service at SISL encompasses policy
                    enforcement and cost management. We provide the best
                    direction for quality control along with activity
                    monitoring.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cloud-service-sec pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="section-header text-center">
                <h2 className="fw-bold fs-1">
                  Google
                  <span className="b-class-secondary">Cloud </span>Platform
                </h2>
                <p className="sec-icon">
                  <i className="fa-solid fa-gear"></i>
                </p>
                <p className="pText">At JR Global, we specialize in harnessing the power of Google Cloud Platform (GCP) to drive innovation and efficiency for our clients. With a team of seasoned experts,
                   we offer comprehensive solutions across various domains</p>
              </div>
            </div>
            <div className="row mt-5 mt-md-4 row-cols-1 row-cols-sm-1 row-cols-md-3 justify-content-center">
              <div className="col">
                <div className="service-card">
                  <div className="icon-wrapper">
                    <i className="fa-solid fa-server"></i>
                  </div>
                  <h3>Application Development</h3>
                  <p>
                    Crafting robust, scalable applications that meet your unique
                    business needs
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="service-card">
                  <div className="icon-wrapper">
                    <i className="fa-solid fa-people-group"></i>
                  </div>
                  <h3>Infrastructure Design</h3>
                  <p>Architecting reliable and secure infrastructure on GCP</p>
                </div>
              </div>
              <div className="col">
                <div className="service-card">
                  <div className="icon-wrapper">
                    <i className="fa-solid fa-globe"></i>
                  </div>
                  <h3>Devops Excellence</h3>
                  <p>
                    Implementing best practices for continuous integration,
                    delivery, and monitoring
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="service-card">
                  <div className="icon-wrapper">
                    <i className="fa-brands fa-cloudversify"></i>
                  </div>
                  <h3>Cloud Migration</h3>
                  <p>Seamlessly transitioning your workloads to GCP</p>
                </div>
              </div>
              <div className="col">
                <div className="service-card">
                  <div className="icon-wrapper">
                    <i className="fa-solid fa-chart-pie"></i>
                  </div>
                  <h3>Data Management</h3>
                  <p>
                    Leveraging GCP’s data services for analytics, storage, and
                    processing
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="service-card">
                  <div className="icon-wrapper">
                    <i className="fa-solid fa-file-invoice-dollar"></i>
                  </div>
                  <h3>Dedicated account management team</h3>
                  <p>
                  We provide our clients with detailed reports and recommendations
                   to improve their business productivity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cloudService3">
          <div>
            <h3 className="h3Text">
              Industries that Benefit
              <br /> the Most from managed Cloud Services
            </h3>
            <p className="text-center pText">
              These sectors are primarily leveraging these latest IT services to
              unlock their growth potential and uncover newer avenues for their
              business.
            </p>
          </div>
          <div className="imageContainer">
            <div className="row">
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/public.jpg" alt="img1" />
                <div className="overlay-text">
                  <h3>Public Sector</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/distribution.jpg" alt="img1" />
                <div className="overlay-text">
                  <h3>Manufacturing and Diastribution</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/IT.webp" alt="img1" />
                <div className="overlay-text">
                  <h3>IT/ITES</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/logistics.webp" alt="img1" />
                <div className="overlay-text">
                  <h3>Logistics</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cloudService4">
          <h2 className="headText">Migration and Deployment</h2>
          <p className="pText">
            We, at JR Global, adopt a cloud-first approach to help clients with
            end-to-end migration of their applications and workloads. Large
            scale cloud migrations are performed by our team with the assurance
            of scalability, security, and improved applications’ performance. {" "}
          </p>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-cloud circleIcon"></i>
                </div>
                <div className="col-md-9">
                  <h4 className="cardHeading">Planning (Roadmap)</h4>
                  <p className="cardText">
                    This stage is all about establishing your cloud strategy in
                    alignment with pre-defined organizational goals. 
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-regular fa-money-bill-1 circleIcon"></i>
                </div>
                <div className="col-md-9">
                  <h4 className="cardHeading">
                    Discovery (Application Readiness)
                  </h4>
                  <p className="cardText">
                    Cloud experts identify applications and workloads which are
                    ready for operating in the cloud environment. 
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-lock circleIcon"></i>
                </div>
                <div className="col-md-9">
                  <h4 className="cardHeading">Design (Architecture Design)</h4>
                  <p className="cardText">
                    Cloud architects will define the components of your
                    applications and databases in a way that you can leverage
                    cloud resources to the fullest.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-shield-halved circleIcon"></i>
                </div>
                <div className="col-md-9">
                  <h4 className="cardHeading">
                    Proof of Concept (Testing in Live Environment)
                  </h4>
                  <p className="cardText">
                    This is the testing phase where we will run your
                    applications in a virtual cloud environment
                    to assess their functioning post-deployment.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-calculator circleIcon"></i>
                </div>
                <div className="col-md-9">
                  <h4 className="cardHeading">Migrate (Live Migration)</h4>
                  <p className="cardText">
                    It’s the execution time with all components of cloud
                    infrastructure in place. Moreover, every application is
                    minutely monitored for its performance on cloud.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-weight-scale circleIcon"></i>
                </div>
                <div className="col-md-9">
                  <h4 className="cardHeading">Validate (Monitoring)</h4>
                  <p className="cardText">
                    Any problem with the optimum functioning of your
                    applications can be diagnosed and rectified at the
                    validation stage. 
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-user-shield circleIcon"></i>
                </div>
                <div className="col-md-9">
                  <h4 className="cardHeading">Operate (Production)</h4>
                  <p className="cardText">
                    It’s the execution time with all components of cloud
                    infrastructure in place. Moreover, every application is
                    minutely monitored for its performance on cloud.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-regular fa-calendar-days circleIcon"></i>
                </div>
                <div className="col-md-9">
                  <h4 className="cardHeading">Optimize (Cost Savings)</h4>
                  <p className="cardText">
                    More resources can be allocated to specific workloads or
                    applications based on your business needs. It helps with
                    cost savings and performance optimization.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Cloud;
