import "./service.css";
import Footer from "../components/Footer.js";
import Header from "../components/Header.js";

const Datacenter = () => {
  return (
    <>
      <Header />
      <div className="banner">
        <div className="headerArea">
          <h1 className="headerText">Data Center Service</h1>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,192L120,202.7C240,213,480,235,720,208C960,181,1200,107,1320,69.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>

      <div className="container">
        <div className="cloudService4 dataWrapper">
          <h2 className="headText">Data Center</h2>
          <p className="pText">
            Get full visibility of your data center infrastructure in a hybrid
            IT environment. You can run multiple applications safely across
            cloud, on premise and the network with our data center management
            services.
          </p>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-database circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">
                    Optimized Data Center Infrastructure
                  </h4>
                  <p className="cardText">
                    Our services continue beyond delivering on service level
                    agreements. We also focus on providing desired outcomes with
                    all your data center needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-arrows-left-right circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Streamlined Operations</h4>
                  <p className="cardText">
                    Manage all operations of your data center environment with
                    scalable infrastructure solutions tailored to your business
                    needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-box-archive circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">
                    Managed Services for Servers & Storage
                  </h4>
                  <p className="cardText">
                    With reliable and scalable storage infrastructure, you can
                    enhance your data performance and take care of the
                    operational management of data center servers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="imageContainer">
      <h3 className="h3Text">
      Industries that Benefit the Most<br/> from Data Center Management <br/>Services
            </h3>
            <p className="text-center pText">
            These sectors are primarily leveraging these latest IT services to unlock their growth potential and uncover newer avenues for their business.
            </p>
      
            <div className="row">
              <div className="col-sm-3 ser3">
                <img className="cloudImg" src="/bioattendence.jpg" alt="attendance" />
                <div className="overlay-text">
                  <h3>Attendance.gov.in</h3>
                </div>
              </div>
              <div className="col-sm-3 ser3">
                <img className="cloudImg" src="/e-taal.webp" alt="e-taal" />
                <div className="overlay-text">
                  <h3>E-Taal</h3>
                </div>
              </div>
              <div className="col-sm-3 ser3">
                <img className="cloudImg" src="/shoot.webp" alt="film" />
                <div className="overlay-text">
                  <h3>National Film Heritage </h3>
                </div>
              </div>
              <div className="col-sm-3 ser3">
                <img className="cloudImg" src="/data.gov.webp" alt="data" />
                <div className="overlay-text">
                  <h3>Data.gov.in</h3>
                </div>
              </div>
            </div>
     </div>
      <Footer />
    </>
  );
};

export default Datacenter;
