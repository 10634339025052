import "../services/service.css";
import Footer from "../components/Footer.js";
import Header from "../components/Header.js";

const About = () => {
  return (
    <>
      <Header />
      <div className="banner">
        <h1 className="headerText">Who We Are</h1>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,192L120,202.7C240,213,480,235,720,208C960,181,1200,107,1320,69.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <img className="aboutImg" src="/work.webp" alt="about" />
          </div>
          <div className="col-md-4">
            <h1 className="heading">OUR VALUES</h1>
            <div className="textContainer">
              <h4>
                <span>V -</span> VISION
              </h4>
              <h4>
                <span>A -</span> ACTION
              </h4>
              <h4>
                <span>L -</span> LEADERSHIP
              </h4>
              <h4>
                <span>U -</span> UNDERSTANDING
              </h4>
              <h4>
                <span>E -</span> ETHICS
              </h4>
              <h4>
                <span>S -</span> SERVICE
              </h4>
            </div>
          </div>
        </div>
        <div className="row aboutWrapper">
          <h1>Why Choose JR Global </h1>
          <p>
            We’re going beyond the ordinary every time and delivering the best
            IT services to our clients globally. Through a vast network of
            technology partners and our strong presence in multiple geographies,
            we have achieved great success with customer service. We offer
            industry-leading IT solutions and cost-effective services for the
            setting up of virtual workspaces, public and private cloud, database
            management, web application development and data analytics.
          </p>

          <div className="aboutContent">
            <div className="row">
              <div className="col-sm-4">
                <i className="fa-solid fa-earth-africa aboutIcon"></i>
                <p>Partnered with Global Tech Giants</p>
              </div>
              <div className="col-sm-4">
                <i className="fa-solid fa-shield-halved aboutIcon"></i>
                <p>Cyber security and data protection services</p>
              </div>
              <div className="col-sm-4">
                <i className="fa-solid fa-coins aboutIcon"></i>
                <p>Deals in Comprehensive IT solutions & Services</p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <i className="fa-solid fa-database aboutIcon"></i>
                <p>Database Services provided by us.</p>
              </div>
              <div className="col-sm-4">
                <i className="fa-solid fa-star aboutIcon"></i>
                <p>Rich Clientele of Government & Enterprise Customers</p>
              </div>
              <div className="col-sm-4">
                <i className="fa-solid fa-award aboutIcon"></i>
                <p>ISO 9001:2013 Certified</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
