import "./service.css";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";

const Licensing = () => {
  return (
    <>
      <Header />
      <div className="banner">
        <div className="headerArea">
          <h1 className="headerText">Licensing Services</h1>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,192L120,202.7C240,213,480,235,720,208C960,181,1200,107,1320,69.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div className="container">
        <div className="cloudService4 fmsIconsData">
          <h2 className="headText">Software Licensing</h2>
          <p className="pText">
          We suggest the right software licensing option to optimize your business performance across Desktop, Server, SaaS or Mobile; and 
          provide real-time visibility into your application usage.
          </p>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-laptop circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Software Asset Management</h4>
                  <p className="cardText">
                  We have adopted proven asset management methodologies to help our clients identify their business assets and ensure that they are correctly and effectively licensed.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-address-card circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Software Licensing Assessment Services</h4>
                  <p className="cardText">
                  Our software licensing assessment services are critical for businesses to obtain data about software and take immediate action.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-user circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Self-Service Monitoring</h4>
                  <p className="cardText">
                  Enjoy uninterrupted access to automated cloud monitoring and analytics. Get the best tools to procure, provision and manage cloud solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
         
        </div>
        <div className="cloudService3">
          <div>
            <h3 className="h3Text">
              Industries that Benefit the Most
              <br /> from Software Licensing
            </h3>
            <p className="text-center pText">
              These sectors are primarily leveraging these latest IT services to
              unlock their growth potential and uncover newer avenues for their
              business.
            </p>
          </div>
          <div className="imageContainer">
            <div className="row">
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/workpeople.jpg" alt="img1" />
                <div className="overlay-text">
                  <h3>Digital India Internship Program</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img
                  className="cloudImg"
                  src="/e-taal.webp"
                  alt="img2"
                />
                <div className="overlay-text">
                  <h3>E-Taal</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/digitalgovt.jpg" alt="img3" />
                <div className="overlay-text">
                  <h3>Digital India</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/e3.jpg" alt="img4" />
                <div className="overlay-text">
                  <h3>Science Program</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Licensing;
