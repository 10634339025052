import "./service.css";
import Footer from "../components/Footer.js";
import Header from "../components/Header.js";
const Fms = () => {
  return (
    <>
     <Header />
     <div className="banner">
        <div className="headerArea">
          <h1 className="headerText">FMS Services</h1>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,192L120,202.7C240,213,480,235,720,208C960,181,1200,107,1320,69.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>
    <div className='container'>
    <div className="cloudService4 fmsIconsData">
          <h2 className="headText">Facility Management Services</h2>
          <p className="pText">
          Deploying manpower across corporate and government bodies, such as NICSI, NIC and FDDI. What help us stay ahead of our competition are – Timely service, extensive candidate database and more.
          </p>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-jet-fighter-up circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Faster Candidate Deployment</h4>
                  <p className="cardText">
                  We ensure candidate deployment within 10 days of issuing of the order, and ensure that different government and corporate projects run uninterruptedly.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-regular fa-clock circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">
                  Timely Payment of Salary
                  </h4>
                  <p className="cardText">
                  At JR Global, we are strongly committed towards timely salary payment, and ensure that the remuneration is processed within five working days of receiving the MPR.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-handshake circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Going Strong in the Government Sector</h4>
                  <p className="cardText">
                  KPMG, Axis Bank, BLK Hospital, PWC and Texmaco Rail are some of our recent 
                  corporate clients, who have aligned with us to power their projects.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-users circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">
                  Undertaking Major Corporate Projects
                  </h4>
                  <p className="cardText">
                    This is the testing phase where we will run your
                    applications in a virtual cloud environment to assess their
                    functioning post-deployment.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-regular fa-calendar-days circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Comprehensive Candidate Database</h4>
                  <p className="cardText">
                  We stand strong with a robust database of over 2K interested profiles in the IT sector,
                   helping us with quick manpower deployment across projects.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-arrows-spin circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">RMS for Employee Lifecycle Management</h4>
                  <p className="cardText">
                  Our exclusive Resource Management System helps with complete employee
                   lifecycle management, right from their hire to retire.
                  </p>
                </div>
              </div>
            </div>
          </div>
         
        </div>
    <div className="cloudService3">
          <div>
            <h3 className="h3Text">
            Industries that Benefit the Most<br/> from Facility Management Services
            </h3>
            <p className="text-center pText">
            These sectors are primarily leveraging these latest IT services to unlock their growth
             potential and uncover newer avenues for their business.
            </p>
          </div>
          <div className="imageContainer">
            <div className="row">
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/fms1.jpeg" alt="img1" />
                <div className="overlay-text">
                  <h3>IT Sector</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/fms2.jpeg" alt="img1" />
                <div className="overlay-text">
                  <h3>Development</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/fms3.jpeg" alt="img1" />
                <div className="overlay-text">
                  <h3>Softwares</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/fms4.jpg" alt="img1" />
                <div className="overlay-text">
                  <h3>Cloud Management</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/fms5.jpg" alt="img1" />
                <div className="overlay-text">
                  <h3>AI Sector</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/fms6.jpg" alt="img1" />
                <div className="overlay-text">
                  <h3>Power Sector</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/fms7.jpg" alt="img1" />
                <div className="overlay-text">
                  <h3>Networking</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/e3.jpg" alt="img1" />
                <div className="overlay-text">
                  <h3>Global Network</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        
    </div>
    <Footer/>
    </>
  )
}

export default Fms