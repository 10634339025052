import "./service.css";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";

const Virtualization = () => {
  return (
    <>
       <Header />
      <div className="banner">
        <div className="headerArea">
          <h1 className="headerText">Virtualization Services</h1>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,192L120,202.7C240,213,480,235,720,208C960,181,1200,107,1320,69.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div className="container">
        <div className="cloudService4 fmsIconsData">
          <h2 className="headText">Virtualization</h2>
          <p className="pText">
          Through server virtualization, storage virtualization, desktop virtualization, application 
                  virtualization and network virtualization, we help clients make a smooth transition to cloud computing and ensure better IT infrastructure management.
                
          </p>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa fa-plane-circle-check circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Provisioning & Deployment</h4>
                  <p className="cardText">
                  We help with faster server virtualization and deployment across businesses of all sizes.
                   A clone of an existing servers can be created without incurring much IT cost</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa fa-fighter-jet circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Improved Disaster Recovery</h4>
                  <p className="cardText">
                  Server virtualization capabilities allow businesses to move a virtual machine from 
                  one server to another quickly and with utmost security.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-credit-card circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Reduced Hardware Costs</h4>
                  <p className="cardText">
                  Hardware utilization is increased significantly, as one 
                  physical server can run multiple applications simultaneously.
                  </p>
                </div>
              </div>
            </div>
          </div>
        
        </div>
        <div className="cloudService3">
          <div>
            <h3 className="h3Text">
              Industries that Benefit the Most
              <br /> from Virtualization services
            </h3>
            <p className="text-center pText">
            These sectors are primarily leveraging these latest IT services to
             unlock their growth potential and uncover newer avenues for their business.
            </p>
          </div>
          <div className="imageContainer">
            <div className="row">
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/storage.jpg" alt="img1" />
                <div className="overlay-text">
                  <h3>Storage Virtualization</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img
                  className="cloudImg"
                  src="/appvirtual.png"
                  alt="img1"
                />
                <div className="overlay-text">
                  <h3>Application Virtualization</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/desktopvir.webp" alt="img1" />
                <div className="overlay-text">
                  <h3>Desktop Virtualization</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/slide8.jpg" alt="img1" />
                <div className="overlay-text">
                  <h3>Network Virtualization</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <Footer/>
    </>
  )
}

export default Virtualization