import "./service.css";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";

const Security = () => {
  return (
    <>
       <Header />
      <div className="banner">
        <div className="headerArea">
          <h1 className="headerText">Security Services</h1>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,192L120,202.7C240,213,480,235,720,208C960,181,1200,107,1320,69.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div className="container">
        <div className="cloudService4 fmsIconsData">
          <h2 className="headText">Security</h2>
          <p className="pText">
          One of the key challenges faced by enterprises today is cyber threats. We can help you devise a robust strategy and use the best security solutions for effective security risk management, monitoring, analysis and reporting.
          </p>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-location-pin-lock circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Endpoint Protection</h4>
                  <p className="cardText">
                  It’s essential to find threat faster and minimize the damage. At the same time, top-notch security solutions also fortify network defense.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-cloud circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Cloud Data Protection</h4>
                  <p className="cardText">
                  Gain multi-cloud security across public, private, and hybrid cloud environment and take concrete steps towards securing your digital transformation.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-user-secret circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Threat and Vulnerability Management</h4>
                  <p className="cardText">
                  The best security solutions to assess your IT security vulnerability and detect 
                  any scope of cybersecurity threats and vulnerabilities.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-building-lock circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Enterprise Risk and Compliance</h4>
                  <p className="cardText">
                  It’s time to devise strategies to develop a more secure and resilient IT environment to improve security and reduce risk.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-shield-halved circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Security Architecture and Implementation</h4>
                  <p className="cardText">
                  The best of security solutions to navigate complex environments and strengthen security architecture and implementation.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-3 circle">
                  <i className="fa-solid fa-unlock circleIcon"></i>
                </div>
                <div className="col-9">
                  <h4 className="cardHeading">Managed Access & Identity Controls</h4>
                  <p className="cardText">
                  Effective identity and risk management to drive business value while reducing risk and responding to the changing IT landscape.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cloudService3">
          <div>
            <h3 className="h3Text">
              Industries that Benefit the Most
              <br /> from Security Services
            </h3>
            <p className="text-center pText">
              These sectors are primarily leveraging these latest IT services to
              unlock their growth potential and uncover newer avenues for their
              business.
            </p>
          </div>
          <div className="imageContainer">
            <div className="row">
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/e-court.jpg" alt="img1" />
                <div className="overlay-text">
                  <h3>My Govt.</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img
                  className="cloudImg"
                  src="/bioattendence.jpg"
                  alt="img1"
                />
                <div className="overlay-text">
                  <h3>Digital Attendance</h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/defence.jpg" alt="img1" />
                <div className="overlay-text">
                  <h3>Defence Procurement Procedure </h3>
                </div>
              </div>
              <div className="col-md-3 ser3">
                <img className="cloudImg" src="/digilock.png" alt="img1" />
                <div className="overlay-text">
                  <h3>Digi Locker</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <Footer/>
    </>
  )
}

export default Security