import "../styles/section3.css";

function Section3() {
  return (
    <div className="section3Wrapper blog">
      <div className="container">
        <div className="blog-head text-center">
          <h2>About Us</h2>
          <h6>Transform your IT Environment</h6>
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-4">
            <div className="item">
              <img src="/e1.jpg" alt="img1" />
                  <h5 className="textBottom">Service</h5>
              <div className="overlay bottom-overlay">
                <div className="p-text">
                  Step 01 <br/>
                  <b>Web Inspection</b><br/>
                  <hr/>Proper inspection of a website with the best tools for the next stage.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="item">
              <img src="/e2.webp" alt="img2" />
                  <h5 className="textBottom">Service</h5>
              <div className="overlay bottom-overlay">
                <div className="p-text">
                Step 02 <br/>
                  <b>Deep Optimization</b><br/>
            <hr/>Optimizing the website with special strategies by covering all its needs.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="item">
              <img src="e3.jpg" alt="img3" />
    <h5 className="textBottom">Service</h5>             
     <div className="overlay bottom-overlay">
                <div className="p-text">
                Step 03 <br/>
                  <b>Analyze Growth</b><br/>
            <hr/>Expert analysis of website data for deploying a strategy for further growth.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="item">
              <img src="e4.webp" alt="img4" />
                  <h5 className="textBottom">Service</h5>
              <div className="overlay bottom-overlay">
                <div className="p-text">
               <b> User Experience (UX) Design</b><br/><hr/>
                Design a user-friendly experience with wireframes or prototypes.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="item">
              <img src="e5.webp" alt="img5" />
                  <h5 className="textBottom">Service</h5>
              <div className="overlay bottom-overlay">
                <div className="p-text"> 
                 <b> Testing and Optimization</b><br/><hr/>
                Test rigorously to find and resolve issues like broken links, browser compatibility, or usability glitches.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="item">
              <img src="e6.webp" alt="img6" />
                  <h5 className="textBottom">Service</h5>
              <div className="overlay bottom-overlay">
                <div className="p-text">
               <b> Maintenance and Updates</b><br/><hr/>
                Keep the website fresh with new content, enhance its features, and fix any tech glitches.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section3;
